import { Stack } from "@fluentui/react"
import styles from './Home.module.css'
import LisLogo from "../../assets/LisLogo-Verde.png";
import { SearchField } from "../../components/SearchField/SearchField";
import { useContext, useEffect, useState } from "react";
import { InputProps, Skeleton, SkeletonItem } from "@fluentui/react-components";
import { AssistantModel, listAssistantsApi } from "../../api";
import { getAccessToken } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { MoreHorizontalFilled, MoreHorizontalRegular, SettingsRegular } from "@fluentui/react-icons";
import { MenuButton } from "../../components/Menu/MenuButton";
import { Can, AbilityContext } from "../../api/security/contextualPermission";
import LisLogotip from "../../components/Logo/LisLogo";
import { handleGetTokenError } from "../../utils/auth";

const assistantSkeletonTemplate = [
    'Assistente 1',
    'Assistente 2',
    'Assistente 3',
    'Assistente 4',
    'Assistente 5'
]



const Home = (props: any) => {
    const { instance } = useMsal();
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [assistants, setAssistants] = useState<AssistantModel[]>([]);
    const menuButtonList = [
        {
            text: "Administrador",
            icon: <SettingsRegular />,
            onClick: () => navigate("/admin")
        }
    ]

    const onChange: InputProps["onChange"] = (ev, data) => {
        // The controlled input pattern can be used for other purposes besides validation,
        // but validation is a useful example
        if (data.value.length <= 20) {
            setSearch(data.value);
        }
    };

    useEffect(() => {
        try {
            const fetchData = async () => {
                const token = await getAccessToken(instance);

                if (!token) {
                    handleGetTokenError(navigate)
                }

                const response = await listAssistantsApi(token);
                if (response?.body) {
                    const result: AssistantModel[] = await response.json();
                    setAssistants(result);
                }
                else {
                    setAssistants([]);
                }
                setIsLoading(false);
            };
            Promise.resolve(fetchData());
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }, [])

    const navigateToAssistant = (assistant: AssistantModel) => {
        navigate(`/assistant-chat/${assistant.id}`, { state: { assistant } })
    }

    return (
        <>
            <Stack horizontal className={styles.homeRoot}>
                <div className={styles.homeContainer}>
                    <div className={styles.homeHeader}>
                        <LisLogotip className={styles.LisIcon} aria-hidden="true" />
                    </div>

                    <div className={styles.homePageContainer}>
                        <span className={styles.welcomeDisclaimer}>Olá, seja bem-vindo. Sou a Lis, a assistente digital da Libbs.<br /> Selecione abaixo sobre qual assunto deseja falar.</span>
                        <Can I="create" a="Assistant">
                            <div className={styles.menuList}><MenuButton menuButtonText="" menuButtonIcon={<MoreHorizontalFilled />} menuItems={menuButtonList} /></div>
                        </Can>
                        <SearchField id="search" value={search} onChange={onChange} />
                        {(isLoading) && (
                            <Stack className={styles.assistantOptionContainer}>
                                <Stack className={styles.assistantOptionGroup}>
                                    {
                                        assistantSkeletonTemplate.map((assistant, index) => (
                                            <Skeleton key={index} className={styles.assistantOptionCardsSkeleton} appearance="opaque" animation="wave">
                                                <SkeletonItem className={styles.skeleton} />
                                            </Skeleton>
                                        ))
                                    }
                                </Stack>
                            </Stack>
                        )
                        }
                        {
                            (!isLoading && assistants.length > 0) && (
                                <Stack className={styles.assistantOptionContainer}>
                                    <Stack className={styles.assistantOptionGroup}>
                                        {
                                            assistants.map((assistant, index) => (
                                                assistant.profile.toLocaleLowerCase().includes(search.toLocaleLowerCase()) &&
                                                <Stack key={index} className={styles.assistantOptionCards} onClick={() => navigateToAssistant(assistant)}><p className={styles.assistantProfileText}>{assistant.profile}</p></Stack>
                                            ))
                                        }
                                    </Stack>
                                </Stack>
                            )
                        }
                        {
                            (!isLoading && assistants.length === 0) && (
                                <Stack className={styles.emptyAssistantOptionContainer}>
                                    <span>Nenhuma assistente por aqui...</span>
                                </Stack>
                            )
                        }
                    </div>
                </div>
            </Stack>
        </>
    )
}

export default Home;