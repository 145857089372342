import {
  Button,
  Menu,
  MenuTrigger,
  MenuList,
  MenuItem,
  MenuPopover,
} from "@fluentui/react-components";

interface MenuButtonProps {
  menuButtonText: string;
  menuButtonIcon?: JSX.Element;
  menuItems: { text: string; icon: JSX.Element; onClick: () => void }[];
}

export const MenuButton = (props: MenuButtonProps) => {
  return (
    <Menu>
      <MenuTrigger disableButtonEnhancement>
        <Button icon={props.menuButtonIcon} style={{ background: '#F0EFEF', color: 'black', border: '1px #BAB4B4', padding: '5px' }}>{props.menuButtonText}</Button>
      </MenuTrigger>

      <MenuPopover>
        <MenuList>
          {
            props.menuItems.map((item, index) => (
              <MenuItem
                key={index}
                icon={item.icon}
                onClick={item.onClick}
              >
                {item.text}
              </MenuItem>
            ))
          }
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};