import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
} from "@fluentui/react-components";
import { Spinner } from "@fluentui/react-components";
import type { SpinnerProps } from "@fluentui/react-components";
import styles from './Modal.module.css';
import { ErrorCircle24Regular, Info24Regular } from '@fluentui/react-icons';
import { ReactElement } from "react";

interface ModalProps {
  modalType?: 'error' | 'wait' | 'info' | undefined;
  color?: string;
  open: boolean;
  title: string;
  text: string | ReactElement;
  buttonCloseText?: string;
  buttonDoSomethingText?: string;
  doSomethingFunc?: () => void;
  closeModalFunc?: () => void;
  displayImage?: string;
}

export const Modal = (props: ModalProps) => {

  const handleCloseModalFunc = () => {
    if (props.closeModalFunc) {
      props.closeModalFunc();
    }
  }

  const handleDoSomethinModalFunc = () => {
    if (props.doSomethingFunc) {
      props.doSomethingFunc();
    }
  }

  const modalTypeObject = () => {
    switch(props.modalType){
      case 'error':
        return <ErrorCircle24Regular style={{color: '#f05348', alignSelf: 'center'}}/>
      case 'wait':
        return <Spinner />
      case 'info':
        return <Info24Regular style={{color: '#0049FF', alignSelf: 'center'}}/>
      default:
        return null
    }
  }

  return (
    <Dialog open={props.open} >
      <DialogSurface className={styles.surfaceModal}>
        <DialogBody className={styles.bodyModal}>
          <div className={styles.contentModal}>
            {
              modalTypeObject()
            }
            <div className={styles.modalTextContainer}>
              <DialogTitle>{props.title}</DialogTitle>
              <DialogContent className={styles.modalTextContainer}>
                {props.text}
              </DialogContent>
              {
                props.displayImage && (
                  <img
                    src={props.displayImage}
                    className={styles.chatIcon}
                    aria-hidden="true"
                  />
                )
              }
            </div>
          </div>
          <DialogActions>
            {
              props.buttonCloseText && (
                <DialogTrigger disableButtonEnhancement>
                  <Button appearance="secondary" onClick={() => handleCloseModalFunc()}>{props.buttonCloseText}</Button>
                </DialogTrigger>
              )
            }
            {
              props.buttonDoSomethingText && (
                <Button appearance="primary" className={styles.buttonConfirm} onClick={() => handleDoSomethinModalFunc()}>{props.buttonDoSomethingText}</Button>
              )
            }
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}