import { useRef, useState, useEffect, useContext, useLayoutEffect } from "react";
import { IconButton, DialogType, Stack } from "@fluentui/react";
import { SquareRegular, ErrorCircleRegular } from "@fluentui/react-icons";

import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import rehypeRaw from "rehype-raw";
import uuid from 'react-uuid';

import styles from "./Assistant.module.css";
import LisLogo from "../../assets/LisLogo-Verde.png";

import {
    ChatMessage,
    Citation,
    AssistantChatMessage,
    ChatResponse,
    Conversation,
    historyClear,
    ChatHistoryLoadingState,
    CosmosDBStatus,
    ErrorMessage,
    FeedbackResponse,
    AssistantModel,
    getAssistantByIdApi,
    assistantFeedbackMessageApi,
    AssistantConversationRequest,
    chatAssistantApi,
    ResultModalProps
} from "../../api";
import { Answer } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ChatHistoryPanel } from "../../components/ChatHistory/ChatHistoryPanel";
import { AppStateContext } from "../../state/AppProvider";
import { useBoolean } from "@fluentui/react-hooks";
import { useMsal } from "@azure/msal-react";
import { getAccessToken } from "../../authConfig";
import { KnownTopicsModal } from "../../components/KnownTopicsModal/KnownTopicsModal";
import { ChatEmptyState } from "../../components/Chat/ChatEmptyState";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Modal } from "../../components/modal/Modal";
import LisLogotip from "../../components/Logo/LisLogo";
import { clearLocalStorageMSAL, handleGetTokenError } from "../../utils/auth";

const enum messageStatus {
    NotRunning = "Not Running",
    Processing = "Processing",
    Done = "Done"
}

const Chat = (props: any) => {
    const appStateContext = useContext(AppStateContext)
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const { assistantId } = useParams();
    const location = useLocation();
    const assistantState: AssistantModel = location.state?.assistant || null
    const [assistant, setAssistant] = useState<AssistantModel | null>(assistantState);
    const [question, setQuestion] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingAssistant, setLoadingAssistant] = useState(true);
    const [modalState, setModalState] = useState<ResultModalProps>({ showResult: false, titleResult: "", result: "", typeResult: undefined });
    const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
    const [activeCitation, setActiveCitation] = useState<[content: string, id: string, title: string, filepath: string, url: string, metadata: string]>();
    const [isCitationPanelOpen, setIsCitationPanelOpen] = useState<boolean>(false);
    const abortFuncs = useRef([] as AbortController[]);
    const [messages, setMessages] = useState<ChatMessage[]>([])
    const [processMessages, setProcessMessages] = useState<messageStatus>(messageStatus.NotRunning);
    const [clearingChat, setClearingChat] = useState<boolean>(false);
    const [hideErrorDialog, { toggle: toggleErrorDialog }] = useBoolean(true);
    const [errorMsg, setErrorMsg] = useState<ErrorMessage | null>();
    const { instance } = useMsal();
    const navigate = useNavigate();

    const errorDialogContentProps = {
        type: DialogType.close,
        title: errorMsg?.title,
        closeButtonAriaLabel: 'Close',
        subText: errorMsg?.subtitle,
    };

    const modalProps = {
        titleAriaId: 'labelId',
        subtitleAriaId: 'subTextId',
        isBlocking: true,
        styles: { main: { maxWidth: 450 } },
    }

    useEffect(() => {
        try{
            newChat();
            const fetchAssistant = async () => {
                const token = await getAccessToken(instance);

                if (!token) {
                    handleGetTokenError(navigate)
                }

                if (assistantId) {
                    setModalState({ showResult: true, titleResult: "Aguarde", result: "Carregando assistente...", typeResult: "wait" });
                    const response = await getAssistantByIdApi(token, assistantId);

                    const handleBackToStart = () => {clearLocalStorageMSAL(); window.location.href = '/'}

                    switch (response.status) {
                        case 200:
                            try {
                                const result: AssistantModel = await response.json();
                                setAssistant(result);
                                setModalState({ showResult: false, titleResult: "Aguarde", result: "Carregando assistente...", typeResult: "wait" });
                                setLoadingAssistant(false);
                                break;
                            } catch (error) {
                                console.warn("Não foi possível carregar o assistente...");
                            }
                        case 403:
                            console.log('Permissão de acessar a assistente falhou')
                            setModalState({ showResult: true, titleResult: "Assistente inacessível", result: "Não foi possível conectar com a assistente.", typeResult: "error", buttonDoSomethingText: "Voltar ao início", buttonDoSomethingOnClick: handleBackToStart });
                            break;
                        case 404:
                            console.log('Não encontrou a assistente')
                            setModalState({ showResult: true, titleResult: "Assistente não encontrada", result: `${assistantId} não foi encontrada. Verifique se o link foi digitado corretamente.`, typeResult: "error", buttonDoSomethingText: "Voltar ao início", buttonDoSomethingOnClick: handleBackToStart });
                            break;
                        default:
                            setModalState({ showResult: true, titleResult: "Erro ao carregar assistente", result: "Não foi possível carregar a assistente, entre em contato com o suporte técnico.", typeResult: "error" });
                            break;
                    }
                }
                else{
                    setModalState({ showResult: true, titleResult: "Assistente não encontrada", result: "A assistente não foi encontrada ou você não possui acesso.", typeResult: "error" });
                }
            }
            console.log(assistantId)
            Promise.resolve(fetchAssistant());
            console.log('carregou assistant')
            chatStart();
        }
        catch(e){
            console.log(e)
            setModalState({ showResult: true, titleResult: "Erro ao carregar assistente", result: "Não foi possível assistente, entre em contato com o suporte técnico.", typeResult: "error" });
        }
    }, []);

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setQuestion(newValue || "");
    };

    function chatStart() {
        if (assistant?.start_message) {
            var initialmessages = [{
                id: uuid(),
                role: "assistant",
                content: assistant.start_message,
                date: new Date().toISOString(),
                user_feedback: null
            }]

            var conversation: Conversation = {
                id: null,
                title: 'chat consultor',
                messages: initialmessages,
                date: new Date().toISOString(),
                assistant_id: assistant.id
            }

            console.log("Iniciando chat...");
            appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
            setMessages(conversation.messages);
            console.log("Chat iniciado:", messages);
        }
    }

    const handleErrorDialogClose = () => {
        toggleErrorDialog()
        setTimeout(() => {
            setErrorMsg(null)
        }, 500);
    }

    const makeApiRequest = async (question: string, conversationId?: string) => {
        if (!assistant) { return; }

        setIsLoading(true);
        setShowLoadingMessage(true);
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);

        const userMessage: ChatMessage = {
            id: uuid(),
            role: "user",
            content: question,
            date: new Date().toISOString(),
        };

        let conversation: Conversation | null | undefined;
        if (!conversationId) {
            conversation = {
                id: conversationId ?? uuid(),
                title: question,
                messages: [...messages, userMessage],
                date: new Date().toISOString(),
                assistant_id: assistant?.id
            }
        } else {
            conversation = appStateContext?.state?.currentChat
            if (!conversation) {
                console.error("Conversation not found.");
                setIsLoading(false);
                setShowLoadingMessage(false);
                abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                return;
            } else {
                conversation.messages.push(userMessage);
            }
        }

        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
        setMessages(conversation.messages)

        const request: AssistantConversationRequest = {
            messages: [...conversation.messages.filter((answer) => answer.role !== "error")],
            history_metadata: {
                conversation_id: conversationId ? conversationId : null,
            },
            assistant: assistant
        };

        let result = {} as ChatResponse;
        try {
            const token = await getAccessToken(instance);

            if (!token) {
                handleGetTokenError(navigate)
            }

            const response = await chatAssistantApi(request, abortController.signal, token);
            if (response?.body) {
                if (response.headers?.get("Content-Type") == "application/json") {
                    let auxResult = await response.json();
                    auxResult.choices[0].messages.forEach((obj: ChatMessage) => {
                        obj.id = uuid();
                        obj.date = new Date().toISOString();
                    })
                    result = auxResult;
                }
                else {
                    const reader = response.body.getReader();
                    let runningText = "";

                    while (true) {
                        setProcessMessages(messageStatus.Processing)
                        const { done, value } = await reader.read();
                        if (done) break;

                        var text = new TextDecoder("utf-8").decode(value);
                        const objects = text.split("\n");
                        objects.forEach((obj) => {
                            try {
                                runningText += obj;
                                result = JSON.parse(runningText);

                                if (conversation) {
                                    conversation.id = result.history_metadata.conversation_id;
                                    conversation.date = result.history_metadata.date;
                                    conversation.title = result.history_metadata.title;
                                }

                                result.choices[0].messages.forEach((obj) => {
                                    obj.id = obj.id;
                                    obj.date = obj.date;
                                    obj.user_feedback = null;
                                })
                                setShowLoadingMessage(false);
                                /** @ts-ignore */
                                setMessages([...conversation?.messages, ...result.choices[0].messages]);
                                runningText = "";
                            }
                            catch { }
                        });
                    }
                }

                conversation.messages.push(...result.choices[0].messages)
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
                setMessages(conversation.messages);
            }

        } catch (e) {
            console.log(e)
            if (!abortController.signal.aborted) {
                let errorMessage = "An error occurred. Please try again. If the problem persists, please contact the site administrator.";
                if (result.error?.message) {
                    errorMessage = result.error.message;
                }
                else if (typeof result.error === "string") {
                    errorMessage = result.error;
                }
                let errorChatMsg: ChatMessage = {
                    id: uuid(),
                    role: "error",
                    content: errorMessage,
                    date: new Date().toISOString(),
                }
                conversation.messages.push(errorChatMsg);
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
                setMessages([...messages, errorChatMsg]);
            } else {
                setMessages([...messages, userMessage])
            }
        } finally {
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            setProcessMessages(messageStatus.Done)
        }

        return abortController.abort();
    };

    const clearChat = async () => {
        setClearingChat(true)
        if (appStateContext?.state.currentChat?.id && appStateContext?.state.isCosmosDBAvailable.cosmosDB) {
            let response = await historyClear(appStateContext?.state.currentChat.id)
            if (!response.ok) {
                setErrorMsg({
                    title: "Error clearing current chat",
                    subtitle: "Please try again. If the problem persists, please contact the site administrator.",
                })
                toggleErrorDialog();
            } else {
                appStateContext?.dispatch({ type: 'DELETE_CURRENT_CHAT_MESSAGES', payload: appStateContext?.state.currentChat.id });
                appStateContext?.dispatch({ type: 'UPDATE_CHAT_HISTORY', payload: appStateContext?.state.currentChat });
                setActiveCitation(undefined);
                setIsCitationPanelOpen(false);
                setMessages([])
            }
        }
        else {
            if (appStateContext?.state.currentChat?.id) {
                window.location.reload();
            }
        }
        setClearingChat(false)
    };

    const newChat = () => {
        setProcessMessages(messageStatus.Processing)
        setMessages([])
        setIsCitationPanelOpen(false);
        setActiveCitation(undefined);
        const conversation = {
            id: null,
            title: 'Nova conversa',
            messages: [],
            date: new Date().toISOString(),
            assistant_id: assistant?.id
        }
        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
        setProcessMessages(messageStatus.Done)
        chatStart()
    };

    const stopGenerating = () => {
        abortFuncs.current.forEach(a => a.abort());
        setShowLoadingMessage(false);
        setIsLoading(false);
    }

    useLayoutEffect(() => {
        chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" })
    }, [showLoadingMessage, processMessages]);

    const onShowCitation = (citation: Citation) => {
        setActiveCitation([citation.content, citation.id, citation.title ?? "", citation.filepath ?? "", citation.url ?? "", ""]);
        setIsCitationPanelOpen(true);
    };

    const parseCitationFromMessage = (message: ChatMessage) => {
        if (message?.role && message?.role === "assistant") {
            try {
                const toolMessage = message as AssistantChatMessage;
                return toolMessage.sources;
            }
            catch {
                return [];
            }
        }
        return [];
    }

    const disabledButton = () => {
        return isLoading || (messages && messages.length === 0) || clearingChat || appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Loading
    }

    const inIframe = () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    const message_feedback_handler = async (message_id: string | undefined, feedback: boolean) => {
        if (!message_id || !assistant || !assistant.id) {
            return;
        }

        const options = {
            id: message_id,
            user_feedback: feedback,
            assistant_id: assistant?.id
        }

        const token = await getAccessToken(instance);

        if (!token) {
            handleGetTokenError(navigate)
        }

        const response = await assistantFeedbackMessageApi(options, token);

        const message_feedback: FeedbackResponse = await response.json()

        const newMessages = messages.map(message =>
            message.id === message_id
                ? { ...message, user_feedback: message_feedback.user_feedback }
                : message
        )

        let conversation = appStateContext?.state?.currentChat

        if (conversation) {
            conversation.messages = newMessages
            appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
            setMessages(newMessages);
        }
    }


    return (
        <div className={inIframe() ? styles.containerIframe : styles.container} role="main">

            <Stack horizontal className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    {
                        !isLoadingAssistant ? (
                            <>
                                <div className={styles.chatHeader}>
                                    <LisLogotip className={styles.chatIcon} aria-hidden="true" />
                                </div>
                                <div className={styles.chatMessageContainer}>
                                    {!messages || messages.length < 1 ? (
                                        <ChatEmptyState chatText={assistant?.description} setQuestion={setQuestion} assistantId={assistantId} />
                                    ) : (
                                        <>
                                            <div className={styles.chatMessageStream} role="log">
                                                {messages.map((answer, index) => (
                                                    <>
                                                        {answer.role === "user" ? (
                                                            <div className={styles.chatMessageUser} tabIndex={0}>
                                                                <div className={styles.chatMessageUserMessage}>{answer.content}</div>
                                                            </div>

                                                        ) : (
                                                            answer.role === "assistant" ? <div className={styles.chatMessageGpt}>
                                                                <Answer
                                                                    answer={{
                                                                        id: answer.id,
                                                                        answer: answer.content,
                                                                        citations: parseCitationFromMessage(answer),
                                                                    }}
                                                                    onCitationClicked={c => onShowCitation(c)}
                                                                    user_feedback={answer.user_feedback}
                                                                    message_feedback_handler={message_feedback_handler}
                                                                    isLoading={isLoading}
                                                                />
                                                            </div> : answer.role === "error" ? <div className={styles.chatMessageError}>
                                                                <Stack horizontal className={styles.chatMessageErrorContent}>
                                                                    <ErrorCircleRegular className={styles.errorIcon} style={{ color: "rgba(182, 52, 67, 1)" }} />
                                                                    <span>Erro</span>
                                                                </Stack>
                                                                <span className={styles.chatMessageErrorContent}>{answer.content}</span>
                                                            </div> : null
                                                        )}
                                                    </>
                                                ))}
                                                {showLoadingMessage && (
                                                    <>
                                                        <div className={styles.chatMessageGpt}>
                                                            <Answer
                                                                answer={{
                                                                    answer: "Gerando resposta...",
                                                                    citations: []
                                                                }}
                                                                onCitationClicked={() => null}
                                                                message_feedback_handler={message_feedback_handler}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                                {isLoading && (
                                                    <Stack
                                                        horizontal
                                                        className={styles.stopGeneratingContainer}
                                                        role="button"
                                                        aria-label="Stop generating"
                                                        tabIndex={0}
                                                        onClick={stopGenerating}
                                                        onKeyDown={e => e.key === "Enter" || e.key === " " ? stopGenerating() : null}
                                                    >
                                                        <SquareRegular className={styles.stopGeneratingIcon} aria-hidden="true" />
                                                        <span className={styles.stopGeneratingText} aria-hidden="true">Cancelar pergunta</span>
                                                    </Stack>
                                                )}
                                                <div ref={chatMessageStreamEnd} />
                                            </div>
                                        </>
                                    )}
                                </div>
                                <QuestionInput
                                    knownTopicsComponent={<KnownTopicsModal assistant={assistant} getAccessTokenFunction={getAccessToken} />}
                                    disabledButton={disabledButton}
                                    clearChat={newChat}
                                    hideErrorDialog={hideErrorDialog}
                                    handleErrorDialogClose={handleErrorDialogClose}
                                    errorDialogContentProps={errorDialogContentProps}
                                    modalProps={modalProps}
                                    onQuestionChange={onQuestionChange}
                                    setQuestion={setQuestion}
                                    question={question}
                                    clearOnSend
                                    placeholder="Digite uma pergunta..."
                                    disabled={isLoading}
                                    onSend={(question, id) => {
                                        makeApiRequest(question, id)
                                    }}
                                    conversationId={appStateContext?.state.currentChat?.id ? appStateContext?.state.currentChat?.id : undefined}
                                />
                            </>
                        ) : (
                            <Modal
                                open={modalState.showResult}
                                title={modalState.titleResult}
                                text={modalState.result}
                                modalType={modalState.typeResult}
                                buttonDoSomethingText={modalState.buttonDoSomethingText}
                                doSomethingFunc={modalState.buttonDoSomethingOnClick}
                            />
                        )
                    }

                </div>
                {messages && messages.length > 0 && isCitationPanelOpen && activeCitation && (
                    <Stack.Item className={styles.citationPanel} tabIndex={0} role="tabpanel" aria-label="Citations Panel">
                        <Stack aria-label="Citations Panel Header Container" horizontal className={styles.citationPanelHeaderContainer} horizontalAlign="space-between" verticalAlign="center">
                            <span aria-label="Citations" className={styles.citationPanelHeader}>Citações</span>
                            <IconButton iconProps={{ iconName: 'Cancel' }} aria-label="Close citations panel" onClick={() => setIsCitationPanelOpen(false)} />
                        </Stack>
                        <h5 className={styles.citationPanelTitle} tabIndex={0}>{activeCitation[2]}</h5>
                        {
                            activeCitation[4] && activeCitation[4] !== "" && (
                                <p>Acesse o documento original <a href={activeCitation[4]} target="_blank">aqui</a></p>
                            )
                        }
                        <div className={styles.citationPanelContainer} tabIndex={0}>
                            <ReactMarkdown
                                linkTarget="_blank"
                                className={styles.citationPanelContent}
                                children={activeCitation[0]}
                                remarkPlugins={[remarkGfm]}
                                rehypePlugins={[rehypeRaw]}
                            />
                        </div>

                    </Stack.Item>
                )}
                {(appStateContext?.state.isChatHistoryOpen && appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured) && <ChatHistoryPanel />}
            </Stack>
        </div>
    );
};

export default Chat;
